import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout title="Informações">
    <div className="row">
      <div className="col s12 unit-map">
        <h1 className="center">Informações</h1>

        <div className="map-information col s12 m6 l6">
          <div className="block-information col s12">
            <h5>Contato</h5>
            <p>Telefone: (11) 2011-8699</p>
            <p>
              <a
                href="https://wa.me/5511958546063"
                target="_blank"
                rel="noreferrer"
              >
                Whatsapp: (11) 95854-6063
              </a>
            </p>
            <p>Rua André de Almeida, 711 Jardim Nove de Julho São Paulo - SP</p>
          </div>

          <div className="block-information col s12">
            <h5>Horário de Funcionamento</h5>
            <div className="col s4 m4 l4 hours">
              <p>
                <strong>Segunta a Quinta</strong>
              </p>
              <p>05:30h às 23:00h</p>
            </div>

            <div className="col s4 m4 l4 hours">
              <p>
                <strong>Sexta</strong>
              </p>
              <p>05:30h às 22:00h</p>
            </div>

            <div className="col s4 m4 l4 hours">
              <p>
                <strong>Sábado</strong>
              </p>
              <p>09:00h às 15:00h</p>
            </div>

            <div className="col s4 m4 l4 hours">
              <p>
                <strong>Domingos e Feriados</strong>
              </p>
              <p>09:00h às 12:00h</p>
            </div>
          </div>
        </div>

        <div className="map-block col s12 m6 l6">
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.35827451742!2d-46.48878218569395!3d-23.59148058466792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce67b7f349e807%3A0xc6cc537f8c9cb60f!2sR.+Andr%C3%A9+de+Almeida%2C+711+-+Cidade+S%C3%A3o+Mateus%2C+S%C3%A3o+Paulo+-+SP!5e0!3m2!1spt-BR!2sbr!4v1473623573212"
            className="col s12"
            width="600"
            height="300"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>

    <div className="line-divider"></div>
  </Layout>
)
